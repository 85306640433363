import { render, staticRenderFns } from "./NvxList.vue?vue&type=template&id=ff125280&scoped=true&"
import script from "./NvxList.vue?vue&type=script&lang=js&"
export * from "./NvxList.vue?vue&type=script&lang=js&"
import style0 from "./NvxList.vue?vue&type=style&index=0&id=ff125280&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff125280",
  null
  
)

export default component.exports