<template>
    <router-link :to="to" class="nvx-cellule">
        <icon-wrapper v-if="icon != null" :iconType="icon" />
        <div class="cell-container">
            <slot/>
        </div>
        <chevron :iconSize="14" />
    </router-link>
</template>

<script>

import Chevron from "@/svg/chevron.vue";
import IconWrapper from "@/components/ui/IconWrapper.vue";

export default {
    name: "NvxRouterCell",

    components: { 
        IconWrapper,
        Chevron 
    },

    props: {
        icon: {
            type: String,
            required: false,
        },
        to: {
            type: String,
            required: true,
        }
    },

    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

@import "@/assets/scss/appmenus/native.scss";

</style>