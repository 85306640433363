<template>
    <div class="nvx-vlist">
        <slot/>
    </div>
</template>

<script>

export default {
    name: "NvxList",

    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

@import "@/assets/scss/appmenus/native.scss";


</style>